<template>
  <div class="news-wapper">
    <div class="con-con">
      <div class="news">
        <div class="title" v-if="this.$route.name == 'tag'">Tag: {{ this.categoryOrTag }}</div>
        <div class="title" v-else>{{ this.categoryOrTag }}</div>
        <div class="news-con" v-if="list && list.length>0">
          <div class="news-item" v-for="(item,index) in list " :key="index"  @click="api.goToDetail(item)">
            <div class="img">
              <template v-if="item.headerImage">
                <img :src="item.headerImage"/>
              </template>
            </div>
            <div class="text">
              <p>{{item.title}} </p>
              <p class='date'>{{item.publishTime | format}} </p>
            </div>
            <button class="btn" v-if="item.tag">{{item.tag.tagName}}</button>
          </div>

        </div>
        <Right/>
      </div>

    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import Right from '@/views/layout/mLayRight'
  import moment from 'moment'
  export default {
    data() {
      return {
        categoryOrTagInUrl: this.$route.params.categoryOrTag,
        categoryOrTag: '',
        list: [],
        api: api,
      }
    },
    mounted() {

    },
    created() {
      this.getNewsList()
    },
    filters: {
      format(val) {
        return moment(val).format('MMMM DD, YYYY') // 默认为英文、引入后设置格式
      }
    },
    computed: {},
    methods: {
      onListReceived(res) {
        if (res.status === 200) {
          this.list = res.data
          if (this.list.length > 0) {
            if (this.$route.name === 'tag') {
              this.categoryOrTag = this.list[0].tag.tagName
            } else {
              this.categoryOrTag = this.list[0].category.categoryName
            }
          } else {
            this.categoryOrTag = this.categoryInUrl
          }
          console.log('categoryOrTag', this.categoryOrTag)
          console.log('news list', this.list)
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          })
        }
      },
      getNewsList() {
        console.log('route.name', this.$route.name, 'categorOrTagInUrl', this.categoryOrTagInUrl)
        if (this.$route.name === 'tag') {
          console.log('handle tag')
          api.getNewsInTag(this.categoryOrTagInUrl).then(this.onListReceived).catch(err => {
            console.log(err)
          })
        } else {
          console.log('handle category')
          api.getNewsInCategory(this.categoryOrTagInUrl).then(this.onListReceived).catch(err => {
            console.log(err)
          })
        }
      }
    },
    components: {
      Right
    }
  }
</script>

<style lang="scss" scoped>
  .con-con {

    margin: 80px auto 0 auto;

    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      /* identical to box height, or 133% */

      text-align: center;
      margin-bottom: 20px;
    }

    .news {
      .sub_title {
        margin-bottom: 44px;
        display: flex;
        justify-content: center;
        align-items: center;

        .sub_item {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */
          text-align: center;
          text-transform: uppercase;
          margin: 0 12px;

          /* Black */

          color: #000000;

        }

        .active {
          border-bottom: 2px solid #2788F0;
        }
      }

      .news-con {
        padding: 0 16px;
        max-width: 420px;
        margin: 0 auto;

        .news-item {
          border: 1px solid #D7D9DD;
          border-radius: 8px;
          overflow: hidden;
          margin-bottom: 16px;

          .img {
            width: 100%;
            height: 262px;
            margin-bottom: 20px;
            overflow: hidden;
            display: flex;
            justify-content: center;

            img {
              max-width: 100%;
              max-height: 262px;
              width: 100%;
              height: auto;
            }
          }

          .text {
            padding: 0 20px;
            height: 75px;
            overflow: hidden;

            p {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #000;
              word-break: break-word;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;

            }
            .date {
                margin-top: 8px;
                font-size: 14px;
            }
          }

          .btn {

            padding: 4px 12px;
            background: #FFFFFF;
            /* Line */
            border: 1px solid #D7D9DD;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #2788F0;
            border-radius: 4px;
            margin-left: 20px;
            margin-top: 20px;
            margin-bottom: 16px;

          }

        }

        .news-item:nth-child(4n) {
          margin-right: 0;
        }

      }

      .news-btn-more {
        width: 144px;
        height: 44px;
        line-height: 44px;
        background: #2788F0;
        border-radius: 8px;
        margin: 40px auto;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        /* white */
        color: #FFFFFF;
      }

    }
  }

</style>
