<template>
  <div class="home">

    <div class="con-con"  >
      <div class="partnership">

        <ul class="partnership-article">
          <li v-for="article in articles" :key="article.title">
            <div class="title">{{ article.sectionHeader }}</div>
            <div v-if="article.image" class="img-tip">
              <img :src="article.image">
            </div>
            <div class="partnership-con">
              <h1>
                <span v-for="(it, i) in article.title.split('\n')" :key="i" style="display: block">{{ it }}</span>
              </h1>
              <p>{{ article.publishTime }}</p>
              <p>{{ article.content }}</p>
              <p class="blue">
                <a :href="article.link" target="__blank" style="color: #2788f0">
                  {{ article.linkLabel }}
                </a>
              </p>
            </div>
          </li>
        </ul>

        <div class="small-sub-title">Collaboration Models</div>
        <div class="img-sub">
          <img src="@/assets/imgs/img_live cell.png">
        </div>
        <div class="key-title-small">
          <img src="@/assets/imgs/img_IMTAC.png">
        </div>
        <div class="small-sub-title-mb">Discover Drugs For Undruggable Targets</div>
        <div class="small-sub-title-grey">Focused Live Cell Screening Against Targets of Interest</div>
        <div class="ship-list">
          <div class="item">
            Undruggable targets
          </div>
          <div class="item">
            Low-expressing targets
          </div>
          <div class="item">
            Disease-driving mutants: like KRAS G12C
          </div>
        </div>
        <div class="key-title-small">
          <img src="@/assets/imgs/img_IMTAC.png">
        </div>
        <div class="small-sub-title-mb">Deconvolute Phenotypic Screening Hits</div>
        <div class="deconvolute">
          <img src="@/assets/imgs/Deconvolute.png">
        </div>
        <div class="key-title-small">
          <img src="@/assets/imgs/img_IMTAC.png">
        </div>
        <div class="small-sub-title-mb">Screening Of Small Molecule Drugs Or Drug Candidates</div>
        <div class="small-sub-title-mb">Identify the small molecules’ previously unknown targets</div>
        <div class="sub-three">
          <div class="three-item">
            <img src="@/assets/imgs/img_molecule.png" class="img">
            <img src="@/assets/imgs/arrow.svg" class="arrow-bottom">
            <div class="item-number">
              <span class="number">1</span>
            </div>
            <div class="text">
              Small Molecule Drugs or Drug Candidates
            </div>
          </div>
          <div class="three-item">
            <img src="@/assets/imgs/img_live cell-1.png" class="img">
            <img src="@/assets/imgs/arrow.svg" class="arrow-bottom">
            <div class="item-number">
              <span class="number">2</span>
            </div>
            <div class="text">
              Live-Cell<span class="blue">Proteome-Wide</span> Screening
            </div>
            <div class="border-txt">
              Access temporary dynamic pockets in native environment
            </div>

          </div>
          <div class="three-item">
            <img src="@/assets/imgs/three.png" class="long">
            <img src="@/assets/imgs/arrow.svg" class="arrow-bottom">
            <div class="item-number">
              <span class="number">3</span>
            </div>
            <div class="text">
              Quantitative Mass spec Analysis
            </div>
            <div class="border-txt">
              <p>1.Identify which targets bind to the small molecules.
              </p>
              <p>2.Quantify their relative binding affinity.</p>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

  // import { mapState, mapGetters } from 'vuex'
  // import api from '@/api'
  import { teamMembers, sabMembers } from '@/assets/html/team'
  import { article1, article2 } from '@/assets/articles'

  export default {
    name: 'home',
    created() {

    },
    data() {
      return {
        newsTab: 0,
        aboutTab: 0,
        title: '',
        htmlContent: '',
        dialogVisible: false,
        teamMembers: teamMembers,
        sabMembers: sabMembers,
        articles: [ article1, article2 ],
        showVideo: false
      }
    },
    mounted() {

    },
    computed: {},
    methods: {
      changeAbout(index) {
        this.aboutTab = index
      },
      openDia(title, content) {
        this.title = title
        this.htmlContent = content
        this.dialogVisible = true
      },
      videoPlay() {
        this.showVideo = true
      },
      closeVideo() {
        this.showVideo = false
      },
      hanldeNavList(type) {
        console.log(type)
      },
      changeNews(index) {
        this.newsTab = index
      },
      goNewsMore() {
        window.open('http://bridgenebio.com/category/all-news/', '_blank')
      },
      goMediaMore() {
        window.open('http://bridgenebio.com/category/all-news/media', '_blank')
      },
      goToUrl(url) {
        window.open(url, '_blank')
      }
    },
    components: {

    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .el-dialog {
    border-radius: 10px !important;
  }
  ::v-deep .el-dialog__body{
    padding-top: 10px !important;
  }

  ::v-deep
  .el-dialog__header {

    .el-dialog__title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height:24px;
      /* identical to box height, or 150% */

      /* Black */

      color: #000000;

    }

    .el-dialog__headerbtn {
      font-size: 28px;

    }
  }

  .dialog-con{
    border-top: 1px solid #D7D9DD;;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height:24px;
    /* or 160% */

    /* Black */
    color: #000000;
    padding: 10px ;
    ::v-deep    p{
      margin: 10px 0;

    }

  }
  .video-banner {
    width: 100vw;

    video {
      width: 100%;
    }

    position: relative;

    .video-list {
      position: absolute;
      left: 28px;
      bottom: 124px;
      width: calc(100vw - 48px);

      li {
        list-style-type: none;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 27px;
        line-height: 30px;

        /* identical to box height, or 133% */

        /* White */

        color: #FFFFFF;

        margin-bottom: 28px;
        text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);

      }
    }
  }

  input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #D7D9DD;
  }

  input:-moz-placeholder, textarea:-moz-placeholder {
    color: #D7D9DD;
  }

  input::-moz-placeholder, textarea::-moz-placeholder {
    color: #D7D9DD;
  }

  input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: #D7D9DD;
  }

  @media (min-width: 400px) {
    .three-item {
      .img {
        /*width: 320px !important;*/
        /*height: 320px !important;*/
      }
      .long{
        width:  320px !important;
      }
      .text{
        font-size: 16px !important;
      }
      .border-txt{
        width: 320px !important;
        font-size: 16px !important;
      }
    }
  }
  .con-con {

    margin: 80px auto;

    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      /* identical to box height, or 133% */

      text-align: center;
      margin-bottom: 20px;
    }

    .key-title-small {
      padding-top: 40px;

      img {
        height: 30px;
      }
    }

    .small-sub-title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      text-align: center;

      /* Black */

      color: #000000;

      margin: 40px 0;

    }

    .small-sub-title-mb {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      /* Black */

      color: #000000;
      margin: 16px 0;
    }

    .small-sub-title-grey {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 160% */

      /* Gray Blue */

      color: #5F6F8F;
    }

    /*about us*/
    .about-wrap {
      width: 100%;

      .sub_title {
        margin-bottom: 44px;
        display: flex;
        justify-content: center;
        align-items: center;

        .sub_item {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */
          text-align: center;
          text-transform: uppercase;
          margin: 0 12px;

          /* Black */

          color: #000000;

        }

        .active {
          border-bottom: 2px solid #2788F0;
        }
      }

      .about-sub-title {
        padding: 10px 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 36px;
        /* identical to box height, or 150% */

        text-align: center;

        /* Black */

        color: #000000;

      }

      .about-con {
        padding: 0 16px;

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 40px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .about-list {
        padding: 0 16px;

        .about-item {
          border: 1px solid #D7D9DD;
          border-radius: 8px;
          overflow: hidden;
          margin-bottom: 16px;
          padding-bottom: 20px;

          .img {
            width: 100%;
            margin-bottom: 20px;
            overflow: hidden;
            display: flex;
            justify-content: center;

            img {
              max-width: 100%;
              max-height: 262px;
              width: 100%;
              height: auto;
            }
          }

          .text {
            padding: 0 20px;
            height: 24px;
            overflow: hidden;

            p {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #000;
              word-break: break-word;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }

          .job {
            padding: 0 20px;
            height: 24px;
            overflow: hidden;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #5F6F8F;
          }

        }

        .news-item:nth-child(4n) {
          margin-right: 0;
        }

      }

    }

    /*PLATFORM*/
    .planform {
      .planform-con {
        padding: 0 16px;

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #000;

          &:last-child {
            padding-bottom: 40px;
            border-bottom: 1px solid #D7D9DD;
          }
        }
      }

      .planform-con-key {
        .key-title {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          margin-bottom: 40px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          text-align: center;

          /* Black */

          color: #000000;

          padding: 40px 0;

          img {
            margin-right: 16px;
            width: 98px;
            height: 30px;
          }

        }

        .gif-con {
          height: 508px;
          border-bottom: 1px solid #D7D9DD;
          margin: 0 16px;

          .gif-item {
            width: 50%;
            text-align: center;
            float: left;
            height: 214px;
            margin-bottom: 40px;

            img {
              width: 128px;
              height: 128px;
              margin-bottom: 16px;
            }

            p {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              /* or 143% */

              text-align: center;

              /* Black */

              color: #000000;

            }
          }
        }

        .con-key-list {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 80px;
          border-bottom: 1px solid #D7D9DD;

          .con-key-item {
            width: 256px;
            margin: 0 32px;

            .img {
              width: 256px;
              height: 256px;
            }

            .text {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 32px;
              text-align: center;
              color: #000000;
            }
          }

        }

        .innovation-con {
          .innovation-l, .innovation-r {
            width: 50vw;
            float: left;

            .innovation-item {
              width: calc(100% - 32px);
              margin: 0 16px;
              float: left;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              margin-bottom: 40px;

              .img {
                height: 60px;
                margin-bottom: 12px;

                img {
                  height: 60px;
                }
              }

              .text-h {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* or 143% */

                /* Black */

                color: #000000;
                margin-bottom: 12px;

              }

              .text-p {
                /* Description */

                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                /* or 133% */

                /* Gray Blue */

                color: #5F6F8F;

              }
            }

          }

        }
      }
    }

    /*PARTNERSHIP*/
    .partnership {
      padding: 0 16px;

      .partnership-article {
        display: flex;
        flex-direction: column;
        gap: 4rem;
      }

      .img-tip {
        display: flex;
        height: 80px;
        justify-content: center;
        align-items: center;
        margin: 24px auto;

        img {
          height: 80px;
        }
      }

      .partnership-con {
        padding-bottom: 40px;
        border-bottom: 1px solid #D7D9DD;

        h1 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #000;
          margin-bottom: 20px;
        }

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #000;
          margin-bottom: 20px;
        }

        p.blue {
          color: #2788F0;
        }
      }

      .deconvolute {
        margin-top: 40px;
      }

      .img-sub, .deconvolute {
        max-width: 360px;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }

      .sub-three {

        .three-item {
          display: flex;
          justify-content: center;
          flex-direction: column;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 40px;

          .img {
            width: 160px;
            height: 160px;
            margin-bottom: 8px;
            display: block;
          }

          .arrow-bottom {
            width: 160px;
            display: block;
            margin-bottom: 8px;
          }

          .item-number {
            height: 24px;
            margin-bottom: 8px;

            .number {
              font-family: Montserrat;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 24px;
              /* identical to box height, or 171% */

              text-align: center;
              text-transform: capitalize;

              /* New Brand */

              color: #2788F0;
              background: #F6F8FA;
              /* Line */

              border: 1px solid #D7D9DD;
              width: 24px;
              height: 24px;
              line-height: 24px;
              display: inline-block;
              border-radius: 50%;

            }
          }

          .text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* or 143% */

            text-align: center;

            /* Black */

            color: #000000;

            .blue {
              color: #2788F0;
            }
          }

          .border-txt {
            margin-top: 10px;
            width: 275px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #5F6F8F;
            padding: 10px;
            /* Yellow */

            border: 1px solid #FFC32D;

            border-radius: 16px;

          }

          .long {
            width: 275px;
          }
        }

      }

      .ship-list {
        width: 100%;
        border-bottom: 1px solid #D7D9DD;
        height: 120px;
        margin-top: 24px;

        .item {
          float: left;
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 133% */

          /* New Brand */

          color: #2788F0;
          padding: 8px 12px;
          border: 1px solid #2788F0;
          box-sizing: border-box;
          border-radius: 8px;
          margin-bottom: 8px;
          margin-right: 8px;
        }

      }
    }

    .pipeline {
      .pipeline-con {
        margin: 0 auto;

        img {
          width: 100%;
        }

        .table-bottom {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          padding: 20px 16px;
          text-align: left;

        }

      }
    }

    .news {
      .sub_title {
        margin-bottom: 44px;
        display: flex;
        justify-content: center;
        align-items: center;

        .sub_item {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */
          text-align: center;
          text-transform: uppercase;
          margin: 0 12px;

          /* Black */

          color: #000000;

        }

        .active {
          border-bottom: 2px solid #2788F0;
        }
      }

      .news-con {
        padding: 0 16px;

        .news-item {
          border: 1px solid #D7D9DD;
          border-radius: 8px;
          overflow: hidden;
          margin-bottom: 16px;

          .img {
            width: 100%;
            height: 262px;
            margin-bottom: 20px;
            overflow: hidden;
            display: flex;
            justify-content: center;

            img {
              max-width: 100%;
              max-height: 262px;
              width: 100%;
              height: auto;
            }
          }

          .text {
            padding: 0 20px;
            height: 72px;
            overflow: hidden;

            p {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #000;
              word-break: break-word;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }

          .btn {

            padding: 4px 12px;
            background: #FFFFFF;
            /* Line */
            border: 1px solid #D7D9DD;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #2788F0;
            border-radius: 4px;
            margin-left: 20px;
            margin-top: 20px;
            margin-bottom: 16px;

          }

        }

        .news-item:nth-child(4n) {
          margin-right: 0;
        }

      }

      .news-btn-more {
        width: 144px;
        height: 44px;
        line-height: 44px;
        background: #2788F0;
        border-radius: 8px;
        margin: 40px auto;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        /* white */
        color: #FFFFFF;
      }

    }
  }

  .line {
    height: 4px;
    width: 100%;
    background: #FFC32D;
  }

  .video {
    width: 100%;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../assets/imgs/video-bg.png") no-repeat center center;
    margin-top: 160px;
    background-size: 100% auto;

    .play {
      width: 64px;
      height: 64px;
      cursor: pointer;
    }
  }

  .bottom-video {
    width: 100%;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../assets/imgs/video-bg.png") no-repeat center center;
    margin-top: 160px;
    background-size: 100% auto;
    position: relative;

    .close-right {
      position: absolute;
      top: 10px;
      right: 10px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 40px;
      cursor: pointer;
      z-index: 10;
    }

    video {
      width: 100%;
      height: 210px;
    }
  }

  .getintouch {
    padding: 80px 16px;
    background: #F6F8FA;

    .con-con {
      margin: 0 !important;
    }

    .con-box {

      .form {
        width: 100%;
        margin-bottom: 40px;

        .form-item {
          border: 1px solid #D7D9DD;
          padding: 12px;
          display: flex;
          margin-bottom: 12px;
          border-radius: 8px;
          background: #fff;

          span.red {
            color: #ff0000;
            margin-right: 5px;
          }

          input, textarea {
            border: none;
            background: transparent;
            width: 400px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
          }

          textarea {
            height: 180px;
          }
        }

        .submit {
          border-radius: 8px;
          background: #2788F0;
          height: 48px;
          width: 100%;
          color: #fff;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 48px;
          text-align: center;
        }
      }

      .form-right-text {
        .right-top {
          margin-bottom: 40px;
        }

        .right-bottom {

          .item {
            margin-top: 20px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            height: 16px;
            color: #000000;
            align-items: center;
            display: flex;
          }
        }

        h1 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          margin-bottom: 12px;
        }

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;

        }

        .copy-right {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          margin-top: 12px;
        }
      }
    }
  }

  @-webkit-keyframes load {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }

  }
</style>
