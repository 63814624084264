<template>
  <div class="page-wrap">
    <div class="page-con">
      <div class="page-l">
        <div class="page-l-con">
          <div class="title">
            {{ careers.title }}
          </div>
          <div class="text" v-html="careers.content" />
          <!-- <div class="form-border">
            <div class="form-title">
              Biology
            </div>
            <div class="form-input" @click="openDia('Research Scientist',rdHtmlContent)">
              <span class="name">Research Scientist</span><img src="@/assets/imgs/right.svg"/>
            </div>
            <div class="form-input" @click="openDia('Senior Research Scientist',rsrHtmlContent)">
              <span class="name">Senior Research Scientist</span><img src="@/assets/imgs/right.svg"/>
            </div>
          </div> -->
          <!--          <div class="form-border">-->
          <!--            <div class="form-title">-->
          <!--              Chemical Biology-->
          <!--            </div>-->
          <!--            <div class="form-input">-->
          <!--              <span class="name">Lab Technician</span><img src="@/assets/imgs/right.svg"/>-->
          <!--            </div>-->
          <!--          </div>-->
          <!-- <div class="form-border">
            <div class="form-title">
              Mass Spectrometry
            </div>
                       <div class="form-input">
                         <span class="name">Lab Technician</span><img src="@/assets/imgs/right.svg"/>
                       </div>
            <div class="form-input"
                 @click="openDia('Research Scientist – Mass Spectrometry and Proteomics Drug Discovery',rsrHtmlContent)">
              <span class="name">Research Scientist – Mass Spectrometry and Proteomics Drug Discovery</span><img
              src="@/assets/imgs/right.svg"/>
            </div>
          </div> -->
          <!-- <div class="form-border">
            <div class="form-title">
              Others
            </div>
            <div class="form-input" @click="openDia('Lab Technician',ltHtmlContent)">
              <span class="name">Lab Technician</span><img src="@/assets/imgs/right.svg"/>
            </div>
                       <div class="form-input">
                         <span class="name">Lab Technician</span><img src="@/assets/imgs/right.svg"/>
                       </div>
                       <div class="form-input">
                         <span class="name">Lab Technician</span><img src="@/assets/imgs/right.svg"/>
                       </div>
                       <div class="form-input">
                         <span class="name">Lab Technician</span><img src="@/assets/imgs/right.svg"/>
                       </div>
          </div> -->
        </div>

      </div>
      <div class="page-r">
        <div class="page-r-con">
          <img src="@/assets/imgs/img_tree.png" class="img-tree"/>
          <div class="title">BRIDGE</div>
          <div class="sub-title"><span class="big">B</span>etter</div>
          <div class="sub-con">Continuously improve work efficiency & accuracy.</div>
          <div class="sub-title"><span class="big">R</span>eliability</div>
          <div class="sub-con">Stable, reliable, repeatable, and traceable.</div>
          <div class="sub-title"><span class="big">I</span>nnovation</div>
          <div class="sub-con">Committed to creating and practicing novel ideas and methods.</div>
          <div class="sub-title"><span class="big">D</span>iversity</div>
          <div class="sub-con">Encourage multi-angle thinking and invite employees with different backgrounds to join
            the
            team.
          </div>
          <div class="sub-title"><span class="big">G</span>ratitude</div>
          <div class="sub-con">Gratitude to coworkers, investors, collaborators, and give back to society.</div>
          <div class="sub-title"><span class="big">E</span>xploration</div>
          <div class="sub-con">Constantly explore new applications of the company's technology.</div>

        </div>

      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="80%"

    >
      <div class="dialog-con" v-html="htmlContent">

      </div>

    </el-dialog>
  </div>
</template>

<script>
  import { rdHtmlContent, rsrHtmlContent, rsmHtmlContent, ltHtmlContent } from '@/assets/html/index'
  import { careers } from '@/assets/articles'

  export default {
    data() {
      return {
        careers: careers,
        dialogVisible: false,
        rdHtmlContent: rdHtmlContent,
        rsrHtmlContent: rsrHtmlContent,
        rsmHtmlContent: rsmHtmlContent,
        ltHtmlContent: ltHtmlContent,
        title: '',
        htmlContent: ''
      }
    },
    mounted() {

    },
    computed: {},
    methods: {
      openDia(title, content) {
        this.title = title
        this.htmlContent = content
        this.dialogVisible = true
      }
    },
    components: {}
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-dialog{
    border-radius: 10px !important;
  }
  ::v-deep
  .el-dialog__header{
   margin:0px 80px;
    padding: 40px 0;
    .el-dialog__title{
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height:52px;
      /* identical to box height, or 150% */

      /* Black */

      color: #000000;

    }
    .el-dialog__headerbtn{
      font-size: 28px;
      right: 80px !important;
      top: 55px !important;
    }
  }
  ::v-deep  .el-dialog__body{
     padding: 0 80px 60px 80px;
  }
  .dialog-con{
    border-top: 1px solid #D7D9DD;;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    word-break: normal;
    /* or 160% */

    /* Black */
    color: #000000;

    ::v-deep    div{
      margin: 40px 0;

    }
    ::v-deep li.li-type:before{
      content: "●";
    }

  }
  @media screen and (max-width: 1700px) {
    .page-r {
      width: 400px;
    }
    .page-l {
      width: calc(100vw - 400px);

      .page-l-con {
        width: 80%;
      }
    }

  }

  @media screen and (min-width: 1701px) {
    .page-r {
      width: 702px;
    }
    .page-l {
      width: calc(100vw - 702px);

      .page-l-con {
        width: 848px;
      }
    }
  }

  .page-wrap {
    background: #E9E9E9;

    .page-con {
      display: flex;
      justify-content: space-between;

      .page-l {

        background: #fff;
        padding: 100px 0;

        .page-l-con {
          margin: 0 auto;

          .title {
            /* h1 */

            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 48px;
            line-height: 60px;
            /* identical to box height, or 125% */

            /* Black */

            color: #000000;
            margin-bottom: 40px;
          }

          .text {
            /* Body */

            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            /* or 160% */

            text-align: justify;

            /* Black */

            color: #000000;
            margin-bottom: 40px;
          }

          .form-border {
            background: #F6F8FA;
            /* Line */

            border: 1px solid #D7D9DD;
            box-sizing: border-box;
            border-radius: 16px;
            padding: 20px;
            margin-bottom: 40px;

            .form-title {
              /* h4 */

              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 32px;
              /* identical to box height, or 160% */

              /* New Brand */

              color: #2788F0;
              margin-bottom: 20px;

            }

            .form-input {

              height: 72px;

              background: #FFFFFF;
              /* Line */

              border: 1px solid #D7D9DD;
              box-sizing: border-box;
              border-radius: 8px;
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 20px;
              cursor: pointer;

              .name {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;

                /* Black */

                color: #000000;

              }
            }
          }
        }

      }

      .page-r {
        /* Background */

        background: #F6F8FA;
        box-shadow: inset 1px 0px 0px #D7D9DD;
        padding: 100px 0;

        .page-r-con {
          padding-left: 40px;
          width: 436px;

          .img-tree {
            width: 352px;
            margin-bottom: 40px;
          }

          .title {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            color: #2788F0;
            padding-bottom: 12px;
            border-bottom: 1px solid #D7D9DD;;
            margin-bottom: 12px;

          }

          .sub-title {
            /* h2 */

            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;

            .big {
              color: #2788F0;
            }
          }

          .sub-con {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            /* identical to box height, or 133% */

            /* Gray Blue */

            color: #5F6F8F;

            margin-bottom: 20px;
          }
        }
      }
    }
  }
</style>
