<template>
  <div class="home">
  
    <div
      class="partnership eks-scale"
      data-height="3700"
      :style="`transform: scale(${scale});`"
    >
    <ul class="partnership-article">
      <li v-for="article in articles" :key="article.title">
        <div class="title">{{ article.sectionHeader }}</div>
        <div v-if="article.image" class="img-tip">
          <img :src="article.image">
        </div>
        <div class="partnership-con">
          <h1>
            <span v-for="(it, i) in article.title.split('\n')" :key="i" style="display: block">{{ it }}</span>
          </h1>
          <p style="text-align: center">{{ article.publishTime }}</p>
          <p>{{ article.content }}</p>
          <p class="blue">
            <a :href="article.link" target="__blank" style="color: #2788f0">
              {{ article.linkLabel }}
            </a>
          </p>
        </div>
      </li>
    </ul>

      <div class="small-sub-title" style="margin: 30px auto">
        Collaboration Models
      </div>

      <div class="models-con">
        <div class="models-lottie">
          <lottie-web-cimo
            ref="model_lottie_web"
            :animationData="modelsArrowDefaultOptions.animationData"
            :path="modelsArrowDefaultOptions.path"
            :loop="modelsArrowDefaultOptions.loop"
            :animation-speed="modelsArrowDefaultOptions.animationSpeed"
            @isLottieFinish="handleLottieFinish"
          />
        </div>
        <div class="model-con-top">
          <div class="model-con-top-l">
            <img src="@/assets/imgs/img_live cell.png" />
          </div>
          <div class="model-con-top-r">
            <div class="img">
              <img src="@/assets/imgs/img_IMTAC.png" />
            </div>
            <h1>Discover Drugs For Undruggable Targets</h1>
            <p>Focused Live Cell Screening Against Targets of Interest</p>
            <div class="model-border">
              <div class="border-item">Undruggable targets</div>
              <div class="border-item">Low-expressing targets</div>
              <div class="border-item">
                Disease-driving mutants: like KRAS G12C
              </div>
            </div>
          </div>
        </div>
        <div class="model-con-mid">
          <img src="@/assets/imgs/img_IMTAC.png" />
          <div class="title">Deconvolute Phenotypic Screening Hits</div>
        </div>
        <div class="model-con-bottom">
          <div class="bottom-list">
            <div class="list-item">Phenotypic Screening</div>
            <div class="list-item">
              Hits
              <div class="lottie-arrow-down">
                <lottie-web-cimo
                  ref="down_lottie_web"
                  :animationData="downDefaultOptions.animationData"
                  :path="downDefaultOptions.path"
                  :loop="downDefaultOptions.loop"
                  :animation-speed="downDefaultOptions.animationSpeed"
                  @isLottieFinish="handleLottieFinish"
                />
              </div>
            </div>
            <div class="item-mid">
              <lottie-web-cimo
                ref="imtac_lottie_web"
                :animationData="imtacCodeDefaultOptions.animationData"
                :path="imtacCodeDefaultOptions.path"
                :loop="imtacCodeDefaultOptions.loop"
                :animation-speed="imtacCodeDefaultOptions.animationSpeed"
                @isLottieFinish="handleLottieFinish"
              />
            </div>
            <div class="list-item blue">
              New Targets
              <div class="lottie-arrow-down">
                <lottie-web-cimo
                  ref="down_lottie_web"
                  :animationData="downDefaultOptions.animationData"
                  :path="downDefaultOptions.path"
                  :loop="downDefaultOptions.loop"
                  :animation-speed="downDefaultOptions.animationSpeed"
                  @isLottieFinish="handleLottieFinish"
                />
              </div>
            </div>
            <div class="list-item">
              Disease Phenotype
              <div class="lottie-arrow-down">
                <lottie-web-cimo
                  ref="down_lottie_web"
                  :animationData="downDefaultOptions.animationData"
                  :path="downDefaultOptions.path"
                  :loop="downDefaultOptions.loop"
                  :animation-speed="downDefaultOptions.animationSpeed"
                  @isLottieFinish="handleLottieFinish"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="key-title-small">
        <img src="@/assets/imgs/img_IMTAC.png" />
      </div>

      <div class="small-sub-title-mb">
        Screening Of Small Molecule Drugs Or Drug Candidates
      </div>
      <div class="small-sub-title-grey">
        Identify the Small Molecules’ Previously Unknown Targets
      </div>

      <div class="ship-list">
        <div class="ship-l">
          <img src="@/assets/imgs/img_molecule.png" />
          Small Molecule Drugs or Drug Candidates
        </div>
        <div class="ship-m">
          <img src="@/assets/imgs/img_live cell-1.png" />
          <h1>Live-Cell <span class="blue">Proteome-Wide</span> Screening</h1>
          <div class="border-text">
            <p>Access temporary dynamic pockets in native environment</p>
          </div>
        </div>
        <div class="ship-r">
          <div class="line-con">
            <div class="line-r-con">
              <div class="line-r-con-l">
                <img src="@/assets/imgs/line.svg" />
              </div>
              <div class="line-r-con-r">
                <div class="item">
                  <div class="json-l">
                    <lottie-web-cimo
                      ref="lottie_web"
                      :animationData="defaultOptions.animationData"
                      :path="defaultOptions.path"
                      :loop="defaultOptions.loop"
                      :animation-speed="defaultOptions.animationSpeed"
                      @isLottieFinish="handleLottieFinish"
                    />
                  </div>
                  <div class="json-r">Drugs with Unknown Mechanism</div>
                </div>
                <div class="item">
                  <div class="json-l">
                    <lottie-web-cimo
                      ref="lottie_web"
                      :animationData="defaultOptions.animationData"
                      :path="defaultOptions.path"
                      :loop="defaultOptions.loop"
                      :animation-speed="defaultOptions.animationSpeed"
                      @isLottieFinish="handleLottieFinish"
                    />
                  </div>
                  <div class="json-r">Off-Target Toxicity</div>
                </div>
                <div class="item">
                  <div class="json-l">
                    <lottie-web-cimo
                      ref="lottie_web"
                      :animationData="defaultOptions.animationData"
                      :path="defaultOptions.path"
                      :loop="defaultOptions.loop"
                      :animation-speed="defaultOptions.animationSpeed"
                      @isLottieFinish="handleLottieFinish"
                    />
                  </div>
                  <div class="json-r">New Indications</div>
                </div>
              </div>
            </div>
          </div>
          <div class="ship-r-bottom">
            <h1>Quantitative Mass Spec Analysis</h1>
            <div class="border-text">
              <p>1. Identify which targets bind to the small molecules.</p>
              <p>2. Quantify their relative binding affinity.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { teamMembers, sabMembers } from "@/assets/html/team";
import { calculateScale } from "@/utils/index";
import LottieWebCimo from "@/components/lottie-web-cimo";
import DoneLottie from "@/assets/lottie/Done.json";
import IMTACCORE from "@/assets/lottie/IMTAC-Core.json";
import KeyComponentsArrow from "@/assets/lottie/Key-Components-Arrow.json";
import ModelsArrow from "@/assets/lottie/ModelsArrow.json";
import Down from "@/assets/lottie/Down.json";
import IMTACCOREArrow from "@/assets/lottie/IMTAC-Croe-Arrow-Right.json";
import CollapseTransition from "@/utils/collapse-transition";
// import { mapState, mapGetters } from 'vuex'
import api from "@/api";
import { article1, article2 } from '@/assets/articles'

export default {
  name: "home",
  created() {},
  data() {
    return {
      articles: [ article1, article2 ],
      scale: 1,
      aboutTab: 0,
      newsTab: 0,
      title: "",
      htmlContent: "",
      dialogVisible: false,
      teamMembers: teamMembers,
      sabMembers: sabMembers,
      showVideo: false,
      defaultOptions: {
        path: "",
        animationData: DoneLottie,
        loop: true,
        animationSpeed: 1,
      },
      KeyComponentsArrowDefaultOptions: {
        path: "",
        animationData: KeyComponentsArrow,
        loop: true,
        animationSpeed: 1,
      },
      imtacCodeDefaultOptions: {
        path: "",
        animationData: IMTACCORE,
        loop: true,
        animationSpeed: 1,
      },
      imtacCodeArrowDefaultOptions: {
        path: "",
        animationData: IMTACCOREArrow,
        loop: true,
        animationSpeed: 1,
      },
      modelsArrowDefaultOptions: {
        path: "",
        animationData: ModelsArrow,
        loop: true,
        animationSpeed: 1,
      },
      downDefaultOptions: {
        path: "",
        animationData: Down,
        loop: true,
        animationSpeed: 1,
      },
      name: "",
      email: "",
      message: "",
      newsList: [],
    };
  },
  mounted() {
    this.getNewsFour();
    this.changeScale();
    window.addEventListener("resize", this.changeScale);
  },
  computed: {},
  methods: {
    openDia(title, content) {
      this.title = title;
      this.htmlContent = content;
      this.dialogVisible = true;
    },
    videoPlay() {
      this.showVideo = true;
    },
    closeVideo() {
      this.showVideo = false;
    },
    changeNews(index) {
      this.newsTab = index;
      this.getNewsFour();
    },
    getNewsFour() {
      const categoryInUrl = this.newsTab === 0 ? "news" : "media";

      api
        .getRecentNewsInCategory(categoryInUrl, 4)
        .then((res) => {
          if (res.status === 200) {
            this.newsList = res.data || [];
          } else {
            this.$message({
              message: res.message,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeAbout(index) {
      this.aboutTab = index;
    },
    goNewsMore() {
      window.open("news/category/news", "_blank");
    },
    goMediaMore() {
      window.open("news/category/media", "_blank");
    },
    play() {
      this.$refs.lottie_web.play();
    },
    stop() {
      this.$refs.lottie_web.stop();
    },
    pause() {
      this.$refs.lottie_web.pause();
    },
    handleLottieFinish(isFinish) {
      // 事件处理
    },
    submitFrom() {
      if (!this.name) {
        this.$message({
          message: "Name is required",
          type: "error",
        });
        return false;
      }
      if (!this.email) {
        this.$message({
          message: "Email is required",
          type: "error",
        });
        return false;
      } else {
        const reg = new RegExp(
          "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
        ); // 正则表达式
        if (!reg.test(this.email)) {
          this.$message({
            message: "Email format is incorrect",
            type: "error",
          });
          return false;
        }
      }
      if (!this.message) {
        this.$message({
          message: "Message is required",
          type: "error",
        });
        return false;
      }
      const params = {
        name: this.name,
        email: this.email,
        message: this.message,
      };
      api
        .submitMsg(params)
        .then((res) => {
          if (res.status === 200) {
            this.$message({
              message: "Message Sent Successfully.",
              type: "success",
            });
            this.name = "";
            this.message = "";
            this.email = "";
          } else {
            this.$message({
              message: res.message,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeScale() {
      console.log("changeScale");
      this.scale = calculateScale(1920);
      let arr = document.querySelectorAll(".eks-scale");
      this.$nextTick(()=>{
        arr.forEach((item) => {
        console.log(item.dataset.height);
        let height = Number(item.dataset.height);
        // let etra = Number(item.dataset.etra) || 0;
        item.style.height = height * this.scale + "px";
      });
      })
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.changeScale);
  },
  components: {
    LottieWebCimo,
    CollapseTransition,
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 10px !important;
}

::v-deep .el-dialog__header {
  margin: 0px 80px;
  padding: 40px 0;

  .el-dialog__title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 52px;
    /* identical to box height, or 150% */

    /* Black */

    color: #000000;
  }

  .el-dialog__headerbtn {
    font-size: 28px;
    right: 80px !important;
    top: 55px !important;
  }
}

::v-deep .el-dialog__body {
  padding: 0 80px 60px 80px;
}

.dialog-con {
  border-top: 1px solid #d7d9dd;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  word-break: normal;
  /* or 160% */

  /* Black */
  color: #000000;

  ::v-deep div,
  ::v-deep p {
    margin: 40px 0;
  }

  ::v-deep li.li-type:before {
    content: "●";
  }
}

.draw-enter-active,
.draw-leave-active {
  transition: all 1s ease;
}

.draw-enter, .draw-leave-to /* .fade-leave-active below version 2.1.8 */ {
  height: 0;
  opacity: 0;
}

.video-banner {
  width: 100vw;

  video {
    width: 100%;
  }
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #d7d9dd;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #d7d9dd;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #d7d9dd;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #d7d9dd;
}

@media (max-width: 1468px) {
  .home {
    margin: 0 auto;
    overflow-x: hidden;
  }

  .con-key-list .con-key-item {
    width: 25% !important;
    margin: 0 !important;
    .img {
      width: 100% !important;
      img {
        width: 100% !important;
      }
    }
  }
  .innovation-top,
  .innovation-mid {
    // .item {
    //   width: 33.33% !important;

    //   .text {
    //     width: 65% !important;
    //   }
    // }
  }
  // .innovation-con {

  //   .innovation-mid .mid-img {
  //     width:30% !important;

  //     .mid-bg-img {
  //       position: absolute;
  //       width: 100%;
  //       z-index: 1;
  //       width: 100% !important;
  //     }

  //     .con-mid {
  //       width: 100px;
  //       position: relative;
  //       z-index: 2;
  //    text-align: center;

  //       img {
  //         width: 100px !important;

  //         margin: 0 auto;
  //         margin-top: -60px !important;
  //       }

  //       .text {
  //         font-family: Montserrat;
  //         font-style: normal;
  //         font-weight: 600;
  //         font-size: 20px;
  //         line-height: 32px;
  //         /* or 160% */

  //         text-align: center;

  //       }
  //     }
  //   }
  // }
  .models-con {
    // width: 100% !important;

    .model-con-top-l {
      // width: 60% !important;

      img {
        width: 100%;
      }
    }

    .model-con-top-r {
      width: 40% !important;
    }
  }
  .model-con-bottom {
    // width: 100% !important;
    // background: url("../../assets/imgs/model_bottom.svg") no-repeat center
    //   center;
    // background-size: 100% auto !important;

    .bottom-list {
      .item-mid {
        // width: 160px !important;
        // height: 160px !important;
      }

      .list-item {
        // width: 140px !important;
        // font-size: 16px !important;
      }
    }
  }
  .ship-list {
    // .ship-l {
    //   width: 28% !important;
    //   img {
    //     width: 100%;
    //   }
    // }
    // .ship-m {
    //   width: 32% !important;

    //   img {
    //     width: 100%;
    //   }
    //   .border-text {
    //     width: 98% !important;
    //     font-size: 16px;
    //     padding: 0 10px !important;
    //     height: auto !important;
    //   }
    // }
    // .ship-r {
    //   width: 40% !important;
    //   .line-r-con-l {
    //     width: 100%;
    //   }
    // }
  }
  .pipeline-con {
    td,
    th {
      // padding: 0 !important;
    }

    .td1 {
      // width: 25vw !important;
    }

    .td2 {
      // width: 20vw !important;
    }

    .td3 {
      // width: 15vw !important;
    }

    .td4 {
      // width: 10vw !important;
    }
  }
}

@media screen and (min-width: 1469px) {
}

@media screen and (max-width: 1150px) {
  .about-list {
    .about-item {
      padding-bottom: 20px !important;

      .img {
        img {
          margin-bottom: 20px !important;
        }
      }

      .text {
        p {
          font-size: 16px !important;
        }
      }

      .job {
        font-size: 16px !important;
      }
    }
  }
}

.video-banner {
  width: 100vw;
  margin: 0 auto;
  position: relative;

  .video-list {
    position: absolute;
    right: 2%;
    bottom: 20%;
    width: 50%;

    li {
      list-style-type: none;
      text-align: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 3rem;
      line-height: 1.333;
      margin-bottom: 8%;
      /* or 125% */

      /* white */

      color: #ffffff;

      text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
    }
  }
}

.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  color: #000000;
  margin-bottom: 40px;
}

.key-title-small {
  margin-top: 120px;
  text-align: center;
}

.small-sub-title {
  font-family: 'Montserrat-Blod';
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Black */

  color: #000000;

  // padding: 40px 0;
}

.small-sub-title-mb {
  font-family: Montserrat-Blod;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Black */

  color: #000000;
  margin: 16px 0;
}

.small-sub-title-grey {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 160% */
  text-align: center;
  /* Gray Blue */
  color: #000000;
}

/*about us*/
.about-wrap {
  padding: 120px 180px 30px 180px;
  width: 1920px;
  box-sizing: border-box;
  transform-origin: 0 0;
  margin-bottom: 5vw;
  .sub_title {
    margin-bottom: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    .sub_item {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      text-align: center;
      text-transform: uppercase;
      margin: 0 12px;
      cursor: pointer;

      /* Black */

      color: #000000;
    }

    .active {
      border-bottom: 2px solid #2788f0;
    }
  }

  .about-list {
    display: flex;
    flex-wrap: wrap;
    /*justify-content:center;*/

    .about-item {
      border: 1px solid #d7d9dd;
      width: calc(25% - 16px);
      border-radius: 8px;
      margin: 0 8px 20px 8px;
      overflow: hidden;
      cursor: pointer;
      padding-bottom: 40px;

      .img {
        width: 100%;
        overflow: hidden;

        img {
          max-width: 100%;
          max-height: 262px;
          width: auto;
          height: auto;
          margin-bottom: 40px;
        }
      }

      .text {
        padding: 0 20px;

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 32px;
          /* identical to box height, or 160% */

          text-align: center;

          /* Black */

          color: #000000;
          word-break: break-word;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .job {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        text-align: center;

        /* Gray Blue */

        color: #5f6f8f;
      }
    }

    .about-item:nth-child(4n) {
      margin-right: 0;
    }
  }

  .about-sub-title {
    padding: 20px 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    text-align: center;

    /* Black */

    color: #000000;
  }

  .about-con {
    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      color: #000;
      margin-bottom: 20px;
      margin-left: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/*PLATFORM*/
.planform {
  padding: 120px 180px 30px 180px;
  width: 1920px;
  box-sizing: border-box;
  transform-origin: 0 0;
  margin-bottom: 5vw;
  .planform-con {
    p {
      text-align: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      color: #000;

      &:last-child {
        padding-bottom: 80px;
        border-bottom: 1px solid #d7d9dd;
      }
    }
  }

  .planform-con-key {
    .key-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      margin-bottom: 40px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      padding: 80px 0;

      img {
        margin-right: 16px;
      }
    }

    .con-key-list-img {
      display: flex;
      justify-content: center;
      align-items: center;

      .img-lottie {
        width: 998px;
        height: 128px;
      }
    }

    .con-key-list {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 80px;
      border-bottom: 1px solid #d7d9dd;

      .con-key-item {
        width: 256px;
        margin: 0 32px;

        .img {
          width: 256px;
          height: 256px;
        }

        .text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 32px;
          text-align: center;
          color: #000000;
        }
      }
    }

    .innovation-con {
      .innovation-mid .mid-img {
        width: 427px;
        height: 320px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .mid-bg-img {
          position: absolute;
          width: 427px;
          height: 320px;
          z-index: 1;
        }

        .con-mid {
          width: 200px;
          position: relative;
          z-index: 2;

          img {
            width: 200px;
            height: 200px;
            margin-top: 85px;
          }

          .text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 32px;
            /* or 160% */

            text-align: center;
          }
        }
      }

      .innovation-top,
      .innovation-mid {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;

        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .text {
            width: 336px;

            h1 {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 32px;
              color: #000000;
            }

            p {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #5f6f8f;
            }
          }
        }

        .item-l {
          h1,
          p {
            text-align: right;
          }
        }

        .item-r {
          h1,
          p {
            text-align: left;
          }
        }
      }
      .innovation-top {
        padding: 0 120px;
      }
    }
  }
}

/*PARTNERSHIP*/
.partnership {
  padding: 120px 180px 30px 180px;
  width: 1920px;
  box-sizing: border-box;
  transform-origin: 0 0;
  margin-bottom: 5vw;
  margin-left: auto;
  margin-right: auto;

  .partnership-article {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  .img-tip {
    display: flex;
    height: 134px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .partnership-con {
    padding-bottom: 80px;
    border-bottom: 1px solid #d7d9dd;

    h1 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #000;
      margin-bottom: 20px;
      text-align: center;
    }

    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: #000;
      margin-bottom: 20px;
      margin-left: 10px;
    }

    p.blue {
      color: #2788f0;
      text-align: center;
    }
  }

  .models-con {
    width: 1220px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 30px;

    .models-lottie {
      position: absolute;
      left: 0;
      right: 0;
      top: 90px;
      bottom: 0;
      margin: auto;
      width: 609px;
      height: 1390px;
      z-index: 1;
    }

    .model-con-top {
      z-index: 2;
      position: relative;
      padding-top: 120px;
      display: flex;
      justify-content: space-between;

      .model-con-top-l {
        width: 664px;
        margin-right: 80px;
      }

      .model-con-top-r {
        .img {
          padding-top: 50px;
        }

        h1 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 36px;
          /* identical to box height, or 150% */

          /* Black */

          color: #000000;
          margin-bottom: 16px;
        }

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          /* identical to box height, or 160% */

          /* Gray Blue */

          color: #5f6f8f;
        }

        .model-border {
          width: 440px;
          padding-top: 40px;

          .border-item {
            padding: 8px 12px;
            height: 40px;
            background: #ffffff;
            /* New Brand */

            border: 1px solid #2788f0;
            box-sizing: border-box;
            border-radius: 8px;
            line-height: 40px;
            margin-right: 12px;
            margin-bottom: 20px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            /* New Brand */

            color: #2788f0;
            float: left;
          }
        }
      }
    }

    .model-con-mid {
      padding-top: 165px;

      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        /* identical to box height, or 150% */

        /* Black */

        color: #000000;
        text-align: left;
      }
    }

    .model-con-bottom {
      position: relative;
      z-index: 2;
      width: 1220px;
      height: 396px;
      background: url("../../assets/imgs/model_bottom.svg") no-repeat center
        center;

      .bottom-list {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
        height: 220px;
        align-items: center;

        .item-mid {
          margin: 0 20px;
          width: 200px;
          height: 200px;
        }

        .list-item {
          width: 180px;
          height: 104px;
          display: flex;
          justify-content: center;
          align-items: center;

          /* white */

          background: #ffffff;
          /* Line */

          border: 1px solid #d7d9dd;
          box-sizing: border-box;
          border-radius: 52px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          /* or 160% */

          text-align: center;

          /* Gray Blue */

          color: #5f6f8f;
          margin: 0 20px;
          position: relative;

          .lottie-arrow-down {
            width: 24px;
            height: 24px;
            position: absolute;
            left: 70px;
            bottom: -12px;
          }
        }

        .blue {
          color: #2788f0;
        }
      }
    }
  }

  .ship-list {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-left: 10px;

    .ship-l {
      width: 228px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      /* or 160% */

      text-align: center;

      /* Black */

      color: #000000;
      img {
        max-width: 100%;
      }
    }

    .ship-m {
      width: 368px;
      text-align: center;

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        /* identical to box height, or 160% */

        text-align: center;

        /* Black */

        color: #000000;
        margin-bottom: 20px;

        .blue {
          color: #2788f0;
        }
      }

      .border-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        width: 368px;
        height: 88px;
        color: #5f6f8f;
        border: 2px solid #ffc32d;
        box-sizing: border-box;
        border-radius: 20px;
        padding: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }

    .ship-r {
      .ship-r-bottom {
        width: 476px;
        margin: 0 auto;
      }

      .line-r-con {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .line-r-con-l {
          width: 457px;
          margin-right: 40px;
        }

        .line-r-con-r {
          .item {
            width: 212px;
            height: 64px;
            padding: 0 8px;
            /* Line */

            border: 1px solid #d7d9dd;

            border-radius: 20px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .json-l {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }

            .json-r {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              /* or 150% */

              /* Black */

              color: #000000;
            }
          }
        }
      }

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        /* identical to box height, or 160% */

        text-align: center;

        /* Black */

        color: #000000;
        margin-bottom: 20px;

        .blue {
          color: #2788f0;
        }
      }

      .border-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        width: 476px;
        height: 120px;
        color: #5f6f8f;
        border: 2px solid #ffc32d;
        box-sizing: border-box;
        border-radius: 20px;
        padding: 12px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /*align-items: center;*/
      }
    }
  }
}

.pipeline {
  padding: 120px 180px 30px 180px;
  width: 1920px;
  box-sizing: border-box;
  transform-origin: 0 0;
  margin-bottom: 5vw;
  .pipeline-con {
    margin: 0 auto;

    .table-bottom {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      margin: 0 124px;
      padding: 40px 0;
      text-align: left;
    }

    table {
      border: 1px solid #d7d9dd;
      box-shadow: 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
        0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
        0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
        0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
        0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
      border-radius: 16px;
      margin: 40px 0px;
      overflow: hidden;
      vertical-align: middle;
      border-bottom: none;
    }

    .td1 {
      width: 447px;
      height: 16px;

      span {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: #ffc32d;
        -webkit-animation: load 4s infinite;
      }
    }

    .td2 {
      width: 248px;
      height: 16px;

      span {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: #2788f0;
        -webkit-animation: load 3s infinite;
      }
    }

    .td3 {
      width: 200px;
      height: 16px;

      span {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: #25d8f0;
        -webkit-animation: load 3s infinite;
      }
    }

    .td4 {
      width: 74px;
      height: 16px;

      span {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: #094c93;
        -webkit-animation: load 3s infinite;
      }
    }

    .table-td {
      width: 217px;

      .small {
        font-size: 16px;
      }
    }

    th {
      background: #f6f8fa;
      height: 120px;
      /*padding: 28px 35px;*/
      box-sizing: border-box;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      /* or 160% */

      text-align: center;

      /* Black */

      color: #000000;
    }

    td {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      /* or 160% */
      border-bottom: 1px solid #d7d9dd;
      text-align: center;

      /* Gray Blue */
      height: 96px;
      color: #5f6f8f;
      padding: 0 20px;
    }

    tr td:nth-child(2n) {
      border-right: 1px solid #d7d9dd;
    }

    tr td:nth-child(3n) {
      text-align: left;
      padding-left: 0;
    }
  }
}

.news {
  padding: 120px 180px 30px 180px;
  width: 1920px;
  box-sizing: border-box;
  transform-origin: 0 0;
  margin-bottom: 5vw;
  .sub_title {
    margin-bottom: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    .sub_item {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      text-align: center;
      text-transform: uppercase;
      margin: 0 12px;
      cursor: pointer;

      /* Black */

      color: #000000;
    }

    .active {
      border-bottom: 2px solid #2788f0;
    }
  }

  .news-con {
    display: flex;
    justify-content: center;
    align-items: center;

    .news-item {
      border: 1px solid #d7d9dd;
      width: 352px;
      height: 452px;
      border-radius: 8px;
      margin-right: 20px;
      overflow: hidden;
      cursor: pointer;

      .img {
        width: 350px;
        height: 262px;
        margin-bottom: 20px;
        overflow: hidden;

        img {
          max-width: 350px;
          max-height: 262px;
          width: auto;
          height: auto;
        }
      }

      .text {
        padding: 0 20px;
        height: 96px;
        overflow: hidden;

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          color: #000;
          word-break: break-word;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }

      .btn {
        padding: 4px 12px;
        background: #ffffff;
        /* Line */
        border: 1px solid #d7d9dd;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #2788f0;
        border-radius: 4px;
        margin-left: 20px;
        margin-top: 20px;
      }
    }

    .news-item:nth-child(4n) {
      margin-right: 0;
    }
  }

  .news-btn-more {
    width: 144px;
    height: 44px;
    line-height: 44px;
    background: #2788f0;
    border-radius: 8px;
    margin: 40px auto;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    /* white */
    color: #ffffff;
  }

  .group-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto;

    .item-button {
      width: 254px;
      margin: 0 32px;
      height: 44px;
      line-height: 44px;
      background: #2788f0;
      border-radius: 8px;
      text-align: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      /* white */
      color: #ffffff;
      cursor: pointer;
    }
  }
}

.line {
  height: 4px;
  width: 100%;
  background: #ffc32d;
}

.video {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/imgs/video-bg.png") no-repeat center center;
  margin-top: 80px;
  background-size: 100% auto;

  .play {
    width: 64px;
    height: 64px;
    cursor: pointer;
  }

  /*video{*/
  /*  width: 100%;*/
  /*  height: 800px;*/
  /*}*/
}

.bottom-video {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/imgs/video-bg.png") no-repeat center center;
  margin-top: 160px;
  background-size: 100% auto;
  position: relative;

  .close-right {
    position: absolute;
    top: 10px;
    right: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 60px;
    cursor: pointer;
    z-index: 10;
  }

  video {
    width: 100%;
    height: 600px;
  }
}

.getintouch {
  padding: 1px 0;
  background: #f6f8fa;

  .con-box {
    display: flex;
    justify-content: space-between;
    width: 960px;

    .form {
      width: 446px;
      padding-right: 40px;
      border-right: 1px solid #d7d9dd;

      .form-item {
        border: 1px solid #d7d9dd;
        padding: 12px;
        display: flex;
        margin-bottom: 12px;
        border-radius: 8px;
        /*align-items: center;*/
        background: #fff;

        span.red {
          color: #ff0000;
          margin-right: 5px;
        }

        input,
        textarea {
          border: none;
          background: transparent;
          width: 400px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
        }

        textarea {
          height: 180px;
        }
      }

      .submit {
        border-radius: 8px;
        background: #2788f0;
        height: 48px;
        width: 100%;
        color: #fff;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 48px;
        text-align: center;
        cursor: pointer;
      }
    }

    .form-right-text {
      .right-top {
        margin-bottom: 80px;
      }

      .right-bottom {
        .item {
          margin-top: 20px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          height: 24px;
          color: #000000;
          align-items: center;
          display: flex;
        }
      }

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 12px;
      }

      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .copy-right {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        margin-top: 12px;
      }
    }
  }
}

@-webkit-keyframes load {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.eks-section-2 {
}
.eks-section-5 {
  height: 746px;
}
.flex-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid red;
}

.eks-container-1 {
  padding: 30px 180px;
  transform-origin: 50% 0;
}
</style>
