<template>
  <div class="page-wrap">
    <div class="page-bottom">
      <div class="title">
        {{ careers.title }}
      </div>
      <div class="text" v-html="careers.content" />
      <!-- <div class="border-text">
        <div class="sub-title">Biology</div>
        <div class="sub-item"  @click="openDia('Research Scientist',rdHtmlContent)">
          <span class="name">Research Scientist</span><img src="@/assets/imgs/right.svg"/>
        </div>
        <div class="sub-item"  @click="openDia('Senior Research Scientist',rsrHtmlContent)">
          <span class="name">Senior Research Scientist</span><img src="@/assets/imgs/right.svg"/>
        </div>
      </div> -->
<!--      <div class="border-text">-->
<!--        <div class="sub-title">Chemical Biology-->
<!--        </div>-->
<!--        <div class="sub-item">-->
<!--          <span class="name">Lab Technician</span><img src="@/assets/imgs/right.svg"/>-->
<!--        </div>-->
<!--        <div class="sub-item">-->
<!--          <span class="name">Lab Technician</span><img src="@/assets/imgs/right.svg"/>-->
<!--        </div>-->
<!--      </div>-->
      <!-- <div class="border-text">
        <div class="sub-title">Mass Spectrometry
        </div>
        <div class="sub-item" @click="openDia('Research Scientist – Mass Spectrometry and Proteomics Drug Discovery',rsmHtmlContent)">
          <span class="name">Research Scientist – Mass Spectrometry and Proteomics Drug Discovery</span><img
          src="@/assets/imgs/right.svg"/>
        </div>
      </div> -->
      <!-- <div class="border-text">
        <div class="sub-title">Others
        </div>
        <div class="sub-item" @click="openDia('Lab Technician',ltHtmlContent)">
          <span class="name">Lab Technician</span><img src="@/assets/imgs/right.svg"/>
        </div>
       <div class="sub-item">
         <span class="name">Lab Technician</span><img src="@/assets/imgs/right.svg"/>
       </div>
       <div class="sub-item">
         <span class="name">Lab Technician</span><img src="@/assets/imgs/right.svg"/>
       </div>
       <div class="sub-item">
         <span class="name">Lab Technician</span><img src="@/assets/imgs/right.svg"/>
       </div>
      </div> -->
    </div>

    <div class="page-top">
      <img src="@/assets/imgs/img_tree.png" class="img-tree"/>
      <div class="title">BRIDGE</div>
      <div class="sub-title"><span class="big">B</span>etter</div>
      <div class="sub-con">Continuously improve work efficiency & accuracy.</div>
      <div class="sub-title"><span class="big">R</span>eliability</div>
      <div class="sub-con">Stable, reliable, repeatable, and traceable.</div>
      <div class="sub-title"><span class="big">I</span>nnovation</div>
      <div class="sub-con">Committed to creating and practicing novel ideas and methods.</div>
      <div class="sub-title"><span class="big">D</span>iversity</div>
      <div class="sub-con">Encourage multi-angle thinking and invite employees with different backgrounds to join the
        team.
      </div>
      <div class="sub-title"><span class="big">G</span>ratitude</div>
      <div class="sub-con">Gratitude to coworkers, investors, collaborators, and give back to society.</div>
      <div class="sub-title"><span class="big">E</span>xploration</div>
      <div class="sub-con">Constantly explore new applications of the company's technology.</div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="96%"

    >
      <div class="dialog-con" v-html="htmlContent">

      </div>

    </el-dialog>
    </div>
</template>

<script>
  /* eslint-disable */
  import { rdHtmlContent, rsrHtmlContent, rsmHtmlContent, ltHtmlContent } from '@/assets/html/index'
  import { careers } from '@/assets/articles'

  export default {
    data() {
      return {
        careers: careers,
        dialogVisible: false,
        rdHtmlContent: rdHtmlContent,
        rsrHtmlContent: rsrHtmlContent,
        rsmHtmlContent: rsmHtmlContent,
        ltHtmlContent: ltHtmlContent,
        title: '',
        htmlContent: ''
      }
    },
    mounted() {
    },
    computed: {},
    methods: {
      openDia(title, content) {
        this.title = title
        this.htmlContent = content
        this.dialogVisible = true
      }
    },
    components: {}
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-dialog {
    border-radius: 10px !important;
  }
  ::v-deep .el-dialog__body{
    padding-top: 10px !important;
  }

  ::v-deep
  .el-dialog__header {

    .el-dialog__title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height:24px;
      /* identical to box height, or 150% */

      /* Black */

      color: #000000;

    }

    .el-dialog__headerbtn {
      font-size: 28px;

    }
  }

  .dialog-con{
    border-top: 1px solid #D7D9DD;;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height:24px;
    word-break: normal;
    /* or 160% */

    /* Black */
    color: #000000;
    padding: 10px ;
    ::v-deep    div{
      margin: 10px 0;

    }
    ::v-deep li.li-type:before{
      content: "●";
    }

  }
  .page-wrap {
    .page-top {
      background: #F6F8FA;
      padding: 40px 16px;

      .img-tree {
        width: 176px;
        margin-bottom: 24px;
      }

      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #2788F0;
        padding-bottom: 12px;
        border-bottom: 1px solid #D7D9DD;;
        margin-bottom: 12px;

      }

      .sub-title {
        /* h2 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;

        .big {
          color: #2788F0;
        }
      }

      .sub-con {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */


        /* Gray Blue */

        color: #5F6F8F;

        margin-bottom: 12px;
      }

    }

    .page-bottom {
      padding: 40px 16px;
      background: #fff;

      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        /* identical to box height, or 133% */

        text-align: center;

        /* Black */

        color: #000000;
        margin-bottom: 24px;

      }

      .text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */


        /* Black */

        color: #000000;
        padding-bottom: 40px;
      }

      .border-text {
        background: #F6F8FA;
        /* Line */
        border: 1px solid #D7D9DD;
        border-radius: 12px;
        padding: 16px;
        margin-bottom: 20px;

        .sub-title {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          /* identical to box height, or 143% */


          /* New Brand */

          color: #2788F0;
          margin-bottom: 16px;

        }

        .sub-item {
          /* white */

          background: #FFFFFF;
          /* Line */

          border: 1px solid #D7D9DD;
          box-sizing: border-box;
          border-radius: 8px;
          padding: 0px 16px;
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
          height: 52px;
          align-items: center;

          .name {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            /* identical to box height, or 143% */


            /* Black */

            color: #000000;

          }
        }

        .sub-item:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
