const rdHtmlContent = `
  <div>
    BridGene Biosciences, Inc. is a newly launched biopharmaceutical company founded by leading scientists from University of South California and Amgen, Inc. BridGene Biosciences employs a proprietary chemoproteomic platform to discover novel therapeutics for the treatment of diverse diseases such as cancer, metabolic disorders, and osteoporosis.
  </div>
  <div>
    Currently we are seeking a talented and motivated individual to join our research team. This is a fantastic opportunity to learn and use cutting-edge science to tackle unmet medical needs. We will work together to build an exciting, science-driven biopharmaceutical company.
  </div>
  <div>
    Key Responsibilities:
  </div>
  <ul>
    <li class="li-type">
      Designing, planning, and conducting various cell-based assays and biochemical assays with multiple biological readouts to characterize lead molecules.
    </li>
  </ul>
  <ul>
    <li class="li-type">
      Executing a range of experiments using molecular biology techniques such as gene expression analysis at mRNA levels and protein levels.
    </li>
  </ul>
  <ul>
    <li class="li-type">
      Investigating and developing new methods and technologies for project advancement..
    </li>
  </ul>
  <div>
    Requirements:
  </div>
  <ul>
    <li class="li-type">
      Ms. or Ph.D in Biology, Biochemistry, or related discipline with more than 5 years of hands-on research experience in either an academic lab or industry setting.
    </li>
  </ul>
  <ul>
    <li class="li-type">
      The ability to manage multiple tasks efficiently, to communicate clearly and effectively, to work independently is essential.
    </li>
  </ul>
  <ul>
    <li class="li-type">
      Prior experience with tissue culture, cell-based and biochemical assays and standard molecular biology techniques is essential.
    </li>
  </ul>
  <ul>
    <li class="li-type">
      Experience with oncology and immunology research/drug discovery is a plus.
    </li>
  </ul>
  <div>
    BridGene Biosciences, Inc. is located in San Francisco Bay Area, California. For immediate consideration, please send your CV and cover letter to: <span style="font-weight: bold; color:#2788F0"><a href="mailto:hr@bridgenebiosciences.com" data-href="mailto:hr@bridgenebiosciences.com" target="_blank" rel="noopener noreferrer" spellcheck="false"  ><span data-string="true">hr@bridgenebiosciences.com</span></a></span>. BridGene Biosciences is proud to be an equal opportunity employer and will consider all qualified applicants for employment.
  </div>
 `

const rsrHtmlContent = `
<div>
  BridGene Biosciences, Inc. is a newly launched biopharmaceutical company founded by leading scientists from University of South California and Amgen, Inc. BridGene Biosciences employs a proprietary chemoproteomic platform to discover novel therapeutics for the treatment of diverse diseases such as cancer, metabolic disorders, and osteoporosis.
</div>
<div>
  Currently we are seeking a talented and motivated individual to join our research team. This is a fantastic opportunity to learn and use cutting-edge science to tackle unmet medical needs. We will work together to build an exciting, science-driven biopharmaceutical company.
</div>
<div>
  Key Responsibilities:
</div>
<ul>
  <li class="li-type">
    Designing, planning, and conducting various cell-based assays and biochemical assays with multiple biological readouts to characterize lead molecules.
  </li>
</ul>
<ul>
  <li class="li-type">
    Executing a range of experiments using molecular biology techniques such as gene expression analysis at mRNA levels and protein levels.
  </li>
</ul>
<ul>
  <li class="li-type">
    Investigating and developing new methods and technologies for project advancement..
  </li>
</ul>
<div>
  Requirements:
</div>
<ul>
  <li class="li-type">
    Ph.D in Biology, Biochemistry, or related discipline with more than 5 years of hands-on research experience in either an academic lab or industry setting.
  </li>
</ul>
<ul>
  <li class="li-type">
    The ability to manage multiple tasks efficiently, to communicate clearly and effectively, to work independently is essential.
  </li>
</ul>
<ul>
  <li class="li-type">
    Prior experience with tissue culture, cell-based and biochemical assays and standard molecular biology techniques is essential.
  </li>
</ul>
<ul>
  <li class="li-type">
    Experience with oncology and immunology research/drug discovery is a plus.
  </li>
</ul>
<div>
  BridGene Biosciences, Inc. is located in San Francisco Bay Area, California. For immediate consideration, please send your CV and cover letter to: <span style="font-weight: bold; color:#2788F0"><a href="mailto:hr@bridgenebiosciences.com" data-href="mailto:hr@bridgenebiosciences.com" target="_blank" rel="noopener noreferrer" spellcheck="false"  ><span data-string="true">hr@bridgenebiosciences.com</span></a></span>. BridGene Biosciences is proud to be an equal opportunity employer and will consider all qualified applicants for employment.
</div>`

const rsmHtmlContent = `
<div>
  BridGene is a biotechnology company focusing on discovering and developing innovative small molecules that drug traditionally undruggable targets, providing new paths to treat diseases. By utilizing its proprietary Chemoproteomics platform IMTAC™, BridGene is able to screen small molecules against all proteins in live cells to discover drug candidates for high value, yet hard-to-drug targets. The ultimate goal is to enable breakthrough small molecule drug discovery and to expand the mechanisms to treat diseases, with targets previously inaccessible to small molecules. The company is advancing a diversified pipeline of first-in-class drugs for targets in multiple disease areas.
</div>
<div>
  Currently we are seeking a talented and motivated individual to join our research team. This is a fantastic opportunity to learn and use cutting-edge science to tackle unmet medical needs. We will work together to build a great, science-driven biopharmaceutical company.
</div>
<div>
  <b>Key Responsibilities:</b>
</div>
<ul>
  <li class="li-type">
    Performing mass spectrometry-based proteomics drug discovery studies, data analysis and trouble shooting
  </li>
</ul>
<ul>
  <li class="li-type">
    Executing a range of experiments cell-based and biochemical assays
  </li>
</ul>
<ul>
  <li class="li-type">
    Investigating and developing new methods and technologies for project advancement.
  </li>
</ul>
<div>
  <b>Requirements:</b>
</div>
<ul>
  <li class="li-type">
    PhD in Analytical Chemistry, Biochemistry with emphasis or strong hands on experience in mass spectrometry.
  </li>
</ul>
<ul>
  <li class="li-type">
    Strong understanding of quantitative proteomics; peptide map method development for post translational modifications.
  </li>
</ul>
<ul>
  <li class="li-type">
    The ability to manage multiple tasks efficiently, to communicate clearly and effectively, to work independently is essential.
  </li>
</ul>
<ul>
  <li class="li-type">
    Prior experience with tissue culture, cell-based and biochemical assays and standard molecular biology techniques is plus.
  </li>
</ul>
<div>
</div>
<div>
  BridGene Biosciences, Inc. is located in San Francisco Bay Area, California. For immediate consideration, please send your CV to <span style="font-weight: bold; color:#2788F0"><a href="mailto:hr@bridgenebiosciences.com" data-href="mailto:hr@bridgenebiosciences.com" target="_blank" rel="noopener noreferrer" spellcheck="false" ><span data-string="true">hr@bridgenebiosciences.com</span></a></span>.
</div>
<div>
  BridGene Biosciences is proud to be an equal opportunity employer and will consider all qualified applicants for employment.
</div>
<div>
</div>`

const ltHtmlContent = `

<div>
  BridGene Biosciences is a biotechnology company dedicated to discovering novel targets and developing new therapeutics for unmet medical needs. BridGene is applying its IMTAC platform to discover druggable opportunities across the entire proteome. With IMTAC, BridGene is able to expand the therapeutic applications of small molecules drugs to a wider range of fields.
</div>
<div>
  Currently we are seeking a laboratory technician to support our research and development teams. The successful candidate will be an integral member of the research team. We will work together to build a great, science-driven biopharmaceutical company.
</div>
<div>
  <b>Key Responsibilities:</b>
</div>
<ul>
  <li class="li-type">
    Support general lab management duties such as maintain lab stock, ordering and receiving supplies, maintaining equipment and organization.
  </li>
</ul>
<ul>
  <li class="li-type">
    Manage and organize chemical inventory and safety records.
  </li>
</ul>
<ul>
  <li class="li-type">
    Perform cell culture and molecular cloning under supervision of senior lab staff.
  </li>
</ul>
<ul>
  <li class="li-type">
    Participate in continuous improvement efforts in the laboratory and company.
  </li>
</ul>
<div>
  <b>Requirements:</b>
</div>
<ul>
  <li class="li-type">
    Bachelor’s degree in biology, chemistry, or related field required. M.S. with &gt;1 year laboratory experience is highly preferred.
  </li>
</ul>
<ul>
  <li class="li-type">
    Minimum 1-2 years of prior experience in biological or chemical laboratory required.
  </li>
</ul>
<ul>
  <li class="li-type">
    Understanding of common molecular and cell biology methods.
  </li>
</ul>
<ul>
  <li class="li-type">
    Hands on experiences with molecular biology techniques and cell culture are preferred.
  </li>
</ul>
<ul>
  <li class="li-type">
    Must possess excellent attention to detail, be organized and can maintain detailed records of experiments and outcomes.
  </li>
</ul>
<ul>
  <li class="li-type">
    Self-motivated and have excellent organization, communication, and computer skills such as Microsoft Office including Word, Excel and PowerPoint.
  </li>
</ul>
<ul>
  <li class="li-type">
    Ability to operate and care for laboratory equipment.
  </li>
</ul>
<ul>
  <li class="li-type">
    Knowledge of standard laboratory management and safety procedures.
  </li>
</ul>
<ul>
  <li class="li-type">
    Proven ability to work both independently and as part of a collaborative team.
  </li>
</ul>
<div>
  BridGene Biosciences, Inc. is located in San Francisco Bay Area, California. For immediate consideration, please send your CV to <span style="font-weight: bold; color:#2788F0"><a href="mailto:hr@bridgenebiosciences.com" data-href="mailto:hr@bridgenebiosciences.com" target="_blank" rel="noopener noreferrer" spellcheck="false"  <span data-string="true">hr@bridgenebiosciences.com</span></a></span>.. BridGene Biosciences is proud to be an equal opportunity employer and will consider all qualified applicants for employment.
</div>`
export {
  rdHtmlContent,
  rsrHtmlContent,
  rsmHtmlContent,
  ltHtmlContent
}
