<template>
  <div class="page-wrap">
    <div class="page-con">
      <div class="page-l">
        <div class="page-l-con">
          <div class="title">{{ this.categoryOrTag }}</div>
          <div class="list-list" v-if="list && list.length > 0">
            <div
              class="list-item"
              v-for="(item,index) in list "
              :key="index"
              @click="api.goToDetail(item)"
            >
              <div class="item-l">
                <div class="text-title">{{ item.title }}</div>
                <p class="time">{{ item.publishTime.split('T')[0]  }}</p>
                <div class="text-content">{{ item.shortDescription }}</div>
                <button class="btn" v-if="item.tag">{{ item.tag.tagName }}</button>
              </div>
              <div class="item-r">
                <template v-if="item.headerImage">
                  <img :src="item.headerImage" />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <Right /> -->
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Right from '@/views/layout/LayRight'

export default {
  data() {
    return {
      categoryOrTagInUrl: this.$route.params.categoryOrTag,
      categoryOrTag: '',
      list: [],
      api: api
    }
  },
  components: {
    Right
  },
  mounted() {

  },
  created() {
    this.getNewsList()
  },
  computed: {},
  methods: {
    onListReceived(res) {
      if (res.status === 200) {
        this.list = res.data
        if (this.list.length > 0) {
          if(this.$route.path === '/news/category/news') {
            this.categoryOrTag = "News & Event"
          }
          else if(this.$route.path === '/news/category/media') {
            this.categoryOrTag = "MEDIA COVERAGE"
          }
          else if (this.$route.name === 'tag') {
            this.categoryOrTag = `Tag: ${this.list[0].tag.tagName}`
          } else if(this.list[0].category) {
            this.categoryOrTag = this.list[0].category.categoryName
          }
        } else {
          this.categoryOrTag = this.categoryInUrl
        }
        console.log('categoryOrTag', this.categoryOrTag)
        console.log('news list', this.list)
      } else {
        this.$message({
          message: res.message,
          type: 'warning'
        })
      }
    },
    getNewsList() {
      console.log('route.name', this.$route.name, 'categorOrTagInUrl', this.categoryOrTagInUrl)
      if (this.$route.name === 'tag') {
        console.log('handle tag')
        api.getNewsInTag(this.categoryOrTagInUrl).then(this.onListReceived).catch(err => {
          console.log(err)
        })
      } else {
        console.log('handle category')
        api.getNewsInCategory(this.categoryOrTagInUrl).then(this.onListReceived).catch(err => {
          console.log(err)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-l {
  // width: 75%;
  .page-l-con {
    width: 85%;
  }
}
.page-wrap {
  // background: #e9e9e9;

  .page-con {
    display: flex;
    justify-content: center;

    .page-l {
      background: #fff;
      padding: 100px 0;

      .page-l-con {
        margin: 0 auto;

        .title {
          /* h1 */

          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 3.3rem;
          line-height: 4rem;
          text-align: center;
          /* identical to box height, or 125% */

          /* Black */

          color: #000000;
          margin-bottom: 40px;
        }

        .list-list {
          width: 100%;
          padding-top: 40px;

          .list-item {
            background: #ffffff;
            /*height: 246px;*/
            /*overflow: hidden;*/
            /* Line */

            border-bottom: 1px solid #d7d9dd;
            box-sizing: border-box;
            margin-bottom: 20px;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            .time {
              font-family: Montserrat;
              font-size: 1.5rem;
              padding: 8px 0;
            }

            .item-l {
              padding-top: 2rem;
              padding-left: 2rem;
              padding-bottom: 1rem;
              padding-right: 2rem;
              width: 64%;

              .text-title {
                // height: 4.75rem;
                padding-bottom: 0.5rem;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 1.6rem;
                line-height: 2.4rem;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
              }

              .text-content {
                height: 3rem;
                /* Description */

                font-family: Montserrat;
                font-style: normal;
                font-weight: 500;
                font-size: 1.1rem;
                line-height: 1.5rem;
                /* or 150% */

                /* Gray Blue */

                color: #5f6f8f;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }

              .btn {
                padding: 4px 12px;
                background: #ffffff;
                /* Line */
                border: 1px solid #d7d9dd;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 500;
                font-size: 1.2rem;
                line-height: 1.8rem;
                color: #2788f0;
                border-radius: 4px;
                // margin-left: 20px;
                margin-top: 1rem;
              }
            }

            .item-r {
              /*width: calc(40% - 40px);*/
              // width: 35%;
             // max-height: 15rem;
              // height: 4rem;

              img {
                width: auto;
                // height: 100%;
                max-width: 20rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
